<template>
  <v-menu
    v-bind="$attrs"
    v-model="model"
  >
    <template
      v-for="(item, index) in getItemActions(menuItem)"
    >
      <v-list
        :key="`item-menu-${index}`"
      >
        <v-list-item
          v-for="action in (isMobileDevice ? item.items : item.items.filter(item => !item.onlyMobile))"
          :key="`item-action-${action.name}`"
          @click="() => onClick({
            action, 
            item: menuItem, 
            fbId: menuItemId
          })"
        >
          <v-list-item-icon>
            <v-icon>
              {{ action.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ action.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider color="#e4e4e4" :key="`item-menu-divider-${index}`"/>
    </template>
  </v-menu>
</template>
<script>
export default {
  props: {
    menuItem: {
      required: true
    },
    menuItemId: {
      type: String,
      required: true
    },
    isMobileDevice: {
      type: Boolean,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
  },
  name: 'MasonryItemMenu',
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onClick(payload) {
      this.$emit('emitAction', payload) 
    },
    getItemActions(item) {
      const { type, favorite } = item

      let result = [
        {
          items: [            
            {
              name: 'preview',
              icon: 'mdi-eye-outline',
              title: 'Preview',
            }
          ]
        },
        {
          items: [
            {
              id: 'select',
              icon: 'mdi-checkbox-marked',
              title: 'Select'
            },
            {
              name: 'moveTo',
              icon: 'mdi-file-move',
              title: 'Move'
            },
            {
              name: 'share',
              icon: 'mdi-share',
              title: 'Share',
              onlyMobile: true
            },
            {
              name: 'favorite',
              icon: 'mdi-star',
              title: `${favorite ? 'Remove from' : 'Add to'} Favorites`
            },
            {
              name: 'edit',
              icon: 'mdi-pencil',
              title: 'Edit'
            }
          ]
        },
        {
          items: [
            {
              name: 'copyClipboard',
              icon: 'mdi-content-copy',
              title: 'Copy to Clipboard'
            },
            {
            name: 'save',
            icon: 'mdi-download',
            title: 'Download'
          }
          ]
        },
        {
          items: [
            {
              name: 'delete',
              icon: 'mdi-delete',
              title: 'Удалить'
            }
          ]
        }
      ]

      switch (type) {
        case 'quote':
          result[1].items.splice(1, 0, {
            name: 'copyAsText',
            icon: 'mdi-clipboard-text-multiple-outline',
            title: 'Copy as text'
          })
          break
      }

      return result
    },
  }
}
</script>